<template>
	<div class="pointer" :class="horizontalScroll ? scrollingClass : 'col-6 col-md-4 col-lg-3'"
		@click="$router.push(`/${event?.experience_category}/${event?.id}`)" v-show="!imageLoading">
		<div class="card-img">
			<img :src="`${storageBucket}/events%2F${event?.id}%2Fcover.jpg?alt=media`" @load="imageLoading = false"
				class="card-img" alt="..." />
		</div>
		<div class="mt-2 mb-5">
			<div class="small text-muted">
				{{
					destinations?.find((destination) => destination?.id === event?.destination)
						?.name
				}}
			</div>
			<div class="h4 text-truncate">{{ event?.name }}</div>
			<div>from {{ formatCurrency(event?.base_price) }}</div>
		</div>
	</div>
	<div class="col-lg-3" :class="horizontalScroll ? scrollingClass : ''" v-show="imageLoading">
		<div class="card-img">
			<Skeletor size="350" />
		</div>
		<div class="mt-2 mb-4">
			<div class="col-5">
				<Skeletor height="15" pill />
			</div>
			<div class="col-10 my-2">
				<Skeletor height="20" pill />
			</div>
			<div class="col-7">
				<Skeletor height="15" pill />
			</div>
		</div>
	</div>
</template>
<script>
import { destinations } from "../../data";
import { formatCurrency } from "../../utils";
export default {
	name: "EventItem",
	props: {
		event: {
			type: Object,
			default: null,
		},
		horizontalScroll: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		storageBucket() {
			return this.$store.state.storageBucket;
		},
	},
	data() {
		return {
			destinations,
			formatCurrency,
			imageLoading: true,
			scrollingClass: "col-10 col-lg-4 me-3 me-lg-4 horizontal-scroller-item pointer",
		};
	},
	mounted() { },
};
</script>
