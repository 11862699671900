<template>
	<div class="col-12 ">
		<div class="alert alert-info">
			<h4 class="my-2"><strong>Why book with Soca Islands</strong></h4>
			<ul class="my-4">
				<li class="mb-4">
					<strong>Collection of quality travel experiences</strong>
					<br/>Quality travel experiences from across the Caribbean to search and book.
				</li>
				<li class="mb-4">
					<strong>Secure online payments</strong>
					<br/>All payments made online are encrypted and secure.
				</li>
				<li class="mb-4">
					<strong>Direct contact with our team</strong>
					<br/>Use Soca Islands WhatsApp and social media platforms to speak directly to us before or after your booking.
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WhyBook',
	setup() {
		
	},
}
</script>