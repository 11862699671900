<template>
	<div class="" id="pageTop">
        
	<div class="row g-3 g-md-4" v-if="recentlyViewed.length > 0"> 
			<div class="col-12 mt-md-5">
				<div class="h2 ">Recently Viewed</div>
			</div> 
			<template v-for="event in recentlyViewed" :key="event.id">
				<EventItem :event="event" />
			</template>
		</div>

		<div class="">  
            <div class="row col-12 my-md-5 pt-md-5">  
				<div class="horizontal-scroller">
					<template v-for="destination in destinations" :key="destination.id">
						<div class="col-2 col-lg-1 me-3 me-lg-4 horizontal-scroller-item align-top pointer" align="center" style="width:70px;" 
						v-if="destination.display" @click="$router.push(`/destinations/${destination.id}`)">
							<img :src="`../assets/img/destinations/${destination.id}.jpg`" class="rounded-circle border border-2 border-light" style="width:70px;" alt="...">
							<div align="center" class="mt-1 text-center" style="font-size: .7rem; white-space: normal; line-height:.75rem;" >
								{{ destination.name }}
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
        
		<div class="row g-2 g-md-4"> 
			<div class="col-12 mt-4 mt-md-4">
                <div class="h2 ">Experience...</div>
            </div> 
			<template v-for="category in experience_categories" :key="category.id">
				<div class="col-6 col-lg-3 pointer" @click="$router.push(`/${category.id}`)">
					<div style="position: relative;">
						<div class="h6" style="position: absolute; top: 1rem; left: .75rem; color:#fff; text-shadow: 0px 0px 1px rgba(0, 0, 0, .5); z-index: 99;">
							{{ category.name }}
						</div>
						<div style="background: #000; position: absolute; top:0; left:0; bottom:0; right:0; opacity:0.15;" class="card-img"></div>
						<img :src="`../assets/img/categories/${category.id}.jpg`" class="card-img" alt="...">
					</div>
				</div>
			</template>
		</div>

        <div class="row mb-4">  
            <div class="col-12 mt-5">
                <div class="h2 mb-4">Featured Activities</div>
            </div> 
			<div class="col-12">
				<div class="horizontal-scroller">
					<template v-for="event in eventsList" :key="event.id">
						<EventItem :event="event" :horizontalScroll="true" />
					</template>
				</div>
			</div>
			<div class="col-12 d-grid d-md-none mt-lg-3 mb-4">
				<button class="btn btn-lg btn-dark">View all</button>
            </div>
		</div>

		<div class="row g-2 g-md-4"> 
			<div class="col-12">
                <div class="h2 mb-2">Celebrate on the Islands</div>
            </div> 
			<template v-for="item in custom_experiences" :key="item.id">
				<div class="col-4 col-lg-2 pointer" @click="$router.push(`/custom-experience?trip=${item.id}`)">
					<img :src="`../assets/img/categories/${item.id}.jpg`" class="card-img" alt="...">
					<div class="text-dark">
						<p class="small text-secondary">{{ item.name }}</p>
					</div>
				</div>
			</template>
		</div>
		<div class="row my-4"> 
			<WhyBook />
		</div>

	</div>
</template>

<script>
import WhyBook from '../components/common/WhyBook.vue';
import EventItem from '../components/experiences/EventItem.vue';
import { destinations, experience_categories, custom_experiences } from '../data';
import { formatCurrency } from '../utils';
export default {
	name: 'Experiences',
	components: {
		EventItem, WhyBook,
	},
	computed:{
		eventsList(){
			const eventsList = this.$store.state.eventsList;
			if(Array.isArray(eventsList)){
				return eventsList.sort((a,b) => 
					b.sort_order > a.sort_order ? -1 : b.sort_order < a.sort_order ? 1 : 0
				);
			}
			return this.$store.state.eventsList;
		},
		storageBucket(){
			return this.$store.state.storageBucket;
		}
	},
	data(){
		return{
			formatCurrency,
			destinations,
			experience_categories,
			custom_experiences,
			isBusy: false,
			recentlyViewed: [],
		}
	},
	methods:{
		loadImages(){
			setTimeout(()=>{
				var imgDefer = document.getElementsByTagName('img')
				console.log(imgDefer.length)
				for (var i=0; i<imgDefer.length; i++) {
					if(imgDefer[i].getAttribute('data-src')) {
						console.log("found")
						imgDefer[i].setAttribute('src',imgDefer[i].getAttribute('data-src'))
					}
					console.log[i]
				}
			}, 100)
			},
	},

	mounted(){
		this.$store.commit("setPageTitle", "Explore")
	},
	created(){

		
	}

}
</script>
