export const countries = [
    { id: "united-states", name: "United States" },
    { id: "canada", name: "Canada" },
    { id: "united-kingdom", name: "United Kingdom" },
    /* {id: "anguilla", name: "Anguilla"},
	{id: "antigua-barbuda", name: "Antigua and Barbuda"}, */
    { id: "argentina", name: "Argentina" },
    /* {id: "aruba", name: "Aruba"}, */
    { id: "australia", name: "Australia" },
    /* {id: "bahamas", name: "Bahamas"},
	{id: "barbados", name: "Barbados"}, */
    /* {id: "belize", name: "Belize"},
	{id: "bermuda", name: "Bermuda"},
	{id: "bonaire", name: "Bonaire"}, */
    { id: "brazil", name: "Brazil" },
    /* {id: "cayman-islands", name: "Cayman Islands"},
	{id: "curacao", name: "Curaçao"}, */
    { id: "denmark", name: "Denmark" },
    /* {id: "dominica", name: "Dominica"},
	{id: "dominican-republic", name: "Dominican Republic"}, */
    { id: "finland", name: "Finland" },
    { id: "france", name: "France" },
    { id: "germany", name: "Germany" },
    { id: "ghana", name: "Ghana" },
    /* {id: "grenada", name: "Grenada"},
	{id: "guadeloupe", name: "Guadeloupe"},
	{id: "guyana", name: "Guyana"}, */
    { id: "hawaii", name: "Hawaii" },
    { id: "ireland", name: "Ireland" },
    { id: "italy", name: "Italy" },
    /* {id: "jamaica", name: "Jamaica"}, */
    { id: "japan", name: "Japan" },
    /* {id: "martinique", name: "Martinique"}, */
    { id: "nigeria", name: "Nideria" },
    { id: "new-zealand", name: "New Zealand" },
    { id: "norway", name: "Norway" },
    { id: "portugal", name: "Portugal" },
    { id: "south-africa", name: "South Africa" },
    { id: "south-korea", name: "South Korea" },
    { id: "spain", name: "Spain" },
    /* {id: "st-kitts-nevis", name: "St. Kitts and Nevis"},
	{id: "st-vincent", name: "St. Vincent and the Grenadines"}, */
    { id: "sweeden", name: "Sweeden" },
    { id: "switzerland", name: "Switzerland" },
    { id: "trinidad-tobago", name: "Trinidad and Tobago" },
    { id: "other", name: "Other" },
];

export const custom_experiences = [
    { id: "birthdays", name: "Birthdays" },
    { id: "incentive-travel", name: "Incentive Travel" },
    { id: "gaycations", name: "Gaycations" },
    { id: "weddings-honeymoons", name: "Wedding + Honeymoons" },
    { id: "bachelor-parties", name: "Bachelor + Bachelorette Parties" },
    { id: "family-reunions", name: "Family Reunions" },
];

export const destinations = [
    {
        id: "australia",
        name: "Australia",
        display: false,
    },
    {
        id: "anguilla",
        name: "Anguilla",
        display: true,
    },
    {
        id: "antigua-barbuda",
        name: "Antigua and Barbuda",
        display: true,
    },
    {
        id: "aruba",
        name: "Aruba",
        display: true,
    },
    {
        id: "barbados",
        name: "Barbados",
        display: true,
    },
    {
        id: "curacao",
        name: "Curaçao",
        display: true,
    },
    {
        id: "dominica",
        name: "Dominica",
        display: true,
    },
    {
        id: "grenada",
        name: "Grenada",
        display: true,
    },
    {
        id: "guyana",
        name: "Guyana",
        display: true,
    },
    {
        id: "jamaica",
        name: "Jamaica",
        display: true,
    },
    {
        id: "st-lucia",
        name: "St. Lucia",
        display: true,
    },
    {
        id: "st-vincent",
        name: "St. Vincent and the Grenadines",
        display: true,
    },
    {
        id: "trinidad-tobago",
        name: "Trinidad and Tobago",
        display: true,
    },
    {
        id: "africa-continent",
        name: "African Continent",
        display: false,
    },
    {
        id: "north-america",
        name: "North America",
        display: false,
    },
    {
        id: "latin-america",
        name: "Latin America",
        display: false,
    },
    {
        id: "europe",
        name: "Europe",
        display: false,
    },
    {
        id: "asia",
        name: "Asia",
        display: false,
    },
];

export const experience_categories = [
    { id: "carnival", name: "Carnival" },
    { id: "culture-festivals", name: "Culture+Festivals" },
    { id: "adventure-travel", name: "Adventure Travel" },
    { id: "work-travel", name: "Work+Travel" },
];

export const gender = [
    { id: "female", name: "Female" },
    { id: "male", name: "Male" },
    { id: "non-binary", name: "Non-binary" },
];

export const marketplace_categories = [
    { id: "accommodations", name: "Accommodations" },
    { id: "carnival-costumes", name: "Carnival Costumes" },
    { id: "fete-tickets", name: "Fete Tickets" },
    { id: "attraction-tickets", name: "Tickets for an Attraction" },
    { id: "jouvert-packages", name: "J'ouvert Packages" },
    { id: "adventure-item", name: "Adventure Item" },
    { id: "other", name: "Other" },
];

export const marketplace_filter = [
    { id: "selling", name: "Selling" },
    { id: "buying", name: "Buying" },
    { id: "trading", name: "Trading" },
];

export const payment_methods = [
    { id: "cash", name: "Cash" },
    { id: "credit-card", name: "Credit Card" },
    { id: "paypal", name: "PayPal Friends and Family" },
    { id: "zelle", name: "Zelle Quickpay" },
    { id: "item-swap", name: "Item Swap" },
    { id: "other", name: "Other" },
];

export const professions = [
    { id: "accounting", name: "Accounting" },
    { id: "advertising", name: "Advertising" },
    { id: "aerospace", name: "Aerospace" },
    { id: "agribusiness", name: "Agribusiness" },
    { id: "airlines", name: "Airlines" },
    { id: "alcoholic-beverages", name: "Alcoholic Beverages" },
    { id: "architecture", name: "Architecture" },
    { id: "attorney", name: "Attorney" },
    { id: "automotive", name: "Automotive" },
    { id: "banking", name: "Banking" },
    { id: "business-services", name: "Business Services" },
    { id: "civil-servant", name: "Civil Servant" },
    { id: "communications", name: "Communications" },
    { id: "construction", name: "Construction" },
    { id: "consultant", name: "Consultant" },
    { id: "education", name: "Education" },
    { id: "entertainment", name: "Entertainment" },
    { id: "engineering", name: "Engineering" },
    { id: "food-beverage", name: "Food and Beverage" },
    { id: "finance", name: "Finance" },
    { id: "government", name: "Governement" },
    { id: "information-technology", name: "Information Technology" },
    { id: "insurance", name: "Insurance" },
    { id: "management", name: "Management" },
    { id: "manufacturing", name: "Manufacturing" },
    { id: "marketing", name: "Manufacturing" },
    { id: "media", name: "Media" },
    { id: "medical-health-professional", name: "Medical Health Professional" },
    { id: "ngo", name: "NGO" },
    { id: "public-relations", name: "Public Relations" },
    { id: "publishing", name: "Publishing" },
    { id: "real-estate", name: "Real Estate" },
    { id: "science", name: "Science" },
    { id: "sports", name: "Sports" },
    { id: "telecommunications", name: "Telecommunications" },
    { id: "tourism", name: "Tourism" },
    { id: "transport", name: "Transport" },
    { id: "venture-capital", name: "Venture Capital" },
    { id: "waste-management", name: "Waste Management" },
    { id: "other", name: "Other" },
];

export const profile_interests = [
    { id: "carnival", name: "Carnival" },
    { id: "charity", name: "Charity" },
    { id: "family", name: "Family" },
    { id: "food", name: "Food" },
    { id: "finding-love", name: "Finding Love" },
    { id: "healthcare-exercise", name: "Healthcare and Exercise" },
    { id: "learning", name: "Learning" },
    { id: "movies", name: "Movies" },
    { id: "photography", name: "Photography" },
    { id: "politics", name: "Politics" },
    { id: "reading", name: "Reading" },
    { id: "sports", name: "Sports" },
    { id: "theatre", name: "Theatre" },
    { id: "travel", name: "Travel" },
];

export const relationship_status = [
    { id: "dating", name: "Dating" },
    { id: "divorced", name: "Divorced" },
    { id: "married", name: "Married" },
    { id: "single", name: "Single" },
    { id: "single-mingle", name: "Single and Looking to Mingle" },
    { id: "situationship", name: "Situationship" },
];

export const vacation_gender = [
    { id: "female-travellers", name: "Female Travellers" },
    { id: "male-travellers", name: "Male Travellers" },
    { id: "non-binary-travellers", name: "Non-binary (LBGTQ) Travellers" },
];

export const vacation_types = [
    { id: "carnival", name: "Carnival Vacation" },
    { id: "adventure-travel", name: "Adventure Travel" },
    { id: "cruise", name: "Cruise" },
    { id: "food-festival", name: "Food Festival" },
    { id: "music-festival", name: "Music Festival" },
    { id: "sporting-event", name: "Sporting Event" },
    { id: "other", name: "Other" },
];
